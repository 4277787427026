import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Games from "../games.json";
import Loader from "../loader";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 w-full">
        {randomGames.map((data, index) => (
          <div
            className="w-full  border border-gray-200 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-300 hover:scale-105"
            key={index}
          >
            <div className="h-64">
              <img
                src={data.image}
                alt={data.title}
                className="h-full w-full object-cover"
              />
            </div>
            <div className="px-4 mt-2">
              <h2 className="mb-2 text-dark text-2xl font-bold leading-[1.5] text-center">
                {data.title}
              </h2>
              <div className="flex mb-2 justify-center">
                <button
                  style={{
                    background:
                      "linear-gradient(45deg, rgb(54, 109, 43), rgb(113, 152, 46))",
                  }}
                  className="mb-2 px-[30px] py-[8px] border-none text-white cursor-pointer rounded-[5px] font-semibold text-[16px]"
                  onClick={() => navigate(`/single?slug=${data.slug}`)}
                >
                  Play
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
