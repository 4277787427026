import React, { useState } from "react";
import Loader from "../loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
  <Loader />
  ) : (
    <>
      {/* <h1 className="text-center text-2xl text-gray-500">About</h1> */}
      <div className="p-6 min-h-screen">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-6">About Us</h1>
          <p className="text-lg mb-4">
            Welcome to <span className="font-bold">HTMLWebGames.Shop</span>! At
            HTMLWebGames.Shop, our goal is to deliver an extensive and diverse
            collection of HTML5 games that you can play instantly on your web
            browser. We believe in providing a top-notch gaming experience
            that’s accessible, fun, and engaging for all kinds of players.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Who We Are</h2>
          <p className="text-lg mb-4">
            We are a team of gaming enthusiasts, developers, and designers
            dedicated to bringing you the best HTML5 games available on the web.
            Our passion for gaming drives us to curate a selection of games that
            cater to various interests and preferences, ensuring there’s
            something for everyone.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">What We Offer</h2>
          <ul className="list-disc text-lg mb-4">
            <li>
              Wide Range of Games: Discover a vast library of HTML5 games across
              multiple genres, including action, puzzle, strategy, adventure,
              and more. Whether you're in the mood for a quick game or a deep
              dive into a new world, you’ll find it here.
            </li>
            <li>
              Quality Selection: We carefully evaluate each game to ensure it
              meets our high standards for quality and performance. Our focus is
              on providing games that are not only enjoyable but also run
              smoothly across different devices and browsers.
            </li>
            <li>
              Instant Play: Play games directly in your web browser with no need
              for downloads or installations. Our platform is designed to offer
              a seamless and hassle-free gaming experience.
            </li>
            <li>
              Community Engagement: Join a vibrant community of gamers where you
              can share your experiences, participate in challenges, and connect
              with fellow enthusiasts.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Our Vision</h2>
          <p className="text-lg mb-4">
            At HTMLWebGames.Shop, we envision becoming the ultimate destination
            for HTML5 gaming. Our mission is to create a space where gamers can
            easily find and enjoy their favorite games while discovering new
            ones that spark their interest.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Our Commitment</h2>
          <ul className="list-disc text-lg mb-4">
            <li>
              Innovation: We continuously seek to innovate and enhance our
              platform, bringing you the latest and most exciting HTML5 games.
            </li>
            <li>
              Quality: We are committed to maintaining a high standard of
              quality by curating and testing each game to ensure a top-notch
              experience.
            </li>
            <li>
              Community: We value our community and strive to create an
              inclusive and welcoming environment for all players.
            </li>
            <li>
              Transparency: We believe in transparency and strive to provide
              clear and honest information about our games and services.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Get In Touch</h2>
          <p className="text-lg mb-4">
            We’d love to hear from you! If you have any questions, feedback, or
            just want to chat about games, feel free to reach out to us at{" "}
            <a
              href="mailto:contact@htmlwebgames.shop"
              className="text-blue-600"
            >
              contact@htmlwebgames.shop
            </a>
            .
          </p>

          <p className="text-lg font-bold text-center mt-6">
            Thank you for visiting HTMLWebGames.Shop. We hope you enjoy your
            gaming experience with us!
          </p>
        </div>
      </div>
    </>
  );
}
