import React, { useState } from "react";
import GamesData from "../games.json";
import { useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Home from "./Home";
import Loader from "../loader";

export default function Single() {
  const [searchParams] = useSearchParams();
  const slug = searchParams.get("slug");
  const game = GamesData.find((game) => game.slug === slug);
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <>
      {isLoad ? (
        <Loader />
      ) : (
        <section className="mx-3">
          <h1 className="text-center text-lime-600 text-xl font-bold my-5">
            Title : {game?.title}
          </h1>
          <div className="w-full rounded-lg h-[650px] block p-3  border border-gray-200 shadow shadow-gray-500 hover:bg-gray-100 overflow-hidden my-5">
            <iframe
              src={game?.gameurl}
              className="w-full h-full overflow-hidden"
              title={game?.title}
            ></iframe>
          </div>
          {game.description && (
            <div
              className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3"
              dangerouslySetInnerHTML={createMarkup(game.description)}
            ></div>
          )}
          {game.sec_desc && (
            <div
              className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3"
              dangerouslySetInnerHTML={createMarkup(game.sec_desc)}
            ></div>
          )}
          <Home />
        </section>
      )}
    </>
  );
}
