import { createSlice } from "@reduxjs/toolkit";

export const LocationSlice = createSlice({
  name: "location",
  initialState: {
    location: "",
  },
  reducers: {
    setLocation: (state, actions) => {
      state.location = actions.payload;
    },
  },
});

export const { setLocation } = LocationSlice.actions;
export default LocationSlice.reducer;
