import React, { useState } from "react";
import Loader from "../loader";

export default function TermCondition() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <Loader />
  ) : (
    <>
      {/* <h1 className="text-center text-2xl text-gray-500">Term Condition</h1> */}
      <div className="p-6 min-h-screen">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-6">
            Terms and Conditions
          </h1>

          <p className="text-lg mb-4">
            Welcome to <span className="font-bold">HTMLWebGames.Shop</span>!
            These Terms and Conditions govern your use of our website and
            services. By accessing or using HTMLWebGames.Shop, you agree to
            comply with and be bound by these terms. If you do not agree with
            any part of these terms, you should not use our website.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            1. Use of the Website
          </h2>
          <p className="text-lg mb-4">
            <span className="font-bold">Eligibility:</span> By using our site,
            you confirm that you are at least 13 years old. If you are under 13,
            you must have parental consent to use our services.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">License:</span> HTMLWebGames.Shop and/or
            its licensors own the intellectual property rights for all material
            on HTMLWebGames.Shop. All intellectual property rights are reserved.
            You may view and/or print pages from HTMLWebGames.Shop for your own
            personal use, subject to the restrictions set in these Terms and
            Conditions.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">Prohibited Uses:</span> You are
            prohibited from:
          </p>
          <ul className="list-disc list-inside text-lg mb-4">
            <li>Republishing material from HTMLWebGames.Shop.</li>
            <li>
              Selling, renting, or sub-licensing material from
              HTMLWebGames.Shop.
            </li>
            <li>
              Reproducing, duplicating, or copying material from
              HTMLWebGames.Shop.
            </li>
            <li>
              Redistributing content from HTMLWebGames.Shop (unless content is
              specifically made for redistribution).
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-6 mb-4">2. User Accounts</h2>
          <p className="text-lg mb-4">
            <span className="font-bold">Account Responsibility:</span> If you
            create an account on HTMLWebGames.Shop, you are responsible for
            maintaining the confidentiality of your account and password and for
            restricting access to your computer. You agree to accept
            responsibility for all activities that occur under your account or
            password.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">Accuracy of Information:</span> You
            agree to provide accurate and complete information when creating
            your account and to keep this information updated.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            3. Purchases and Payments
          </h2>
          <p className="text-lg mb-4">
            <span className="font-bold">Pricing:</span> All prices listed on our
            website are subject to change without notice. We strive to ensure
            that all pricing information is accurate, but errors may occur. If
            we discover an error in the price of any product or service you have
            ordered, we will inform you as soon as possible and offer you the
            option to reconfirm your order at the correct price or cancel it.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">Payment Methods:</span> We accept
            various payment methods, including credit/debit cards and PayPal. By
            providing payment information, you represent and warrant that the
            information is accurate and that you are authorized to use the
            payment method provided.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            4. Delivery and Access
          </h2>
          <p className="text-lg mb-4">
            <span className="font-bold">Digital Products:</span> Purchased
            digital products will be delivered electronically to the email
            address associated with your account or made available for download
            from your account page.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">Service Access:</span> We strive to
            ensure that our website and services are available at all times.
            However, we do not guarantee uninterrupted access and may suspend
            access for maintenance or other reasons.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            5. Returns and Refunds
          </h2>
          <p className="text-lg mb-4">
            <span className="font-bold">Digital Products:</span> All sales of
            digital products are final. We do not offer refunds or exchanges for
            digital products unless required by law.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">Physical Products:</span> Returns and
            refunds for physical products are subject to our Return Policy,
            which is available on our website.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">6. Disclaimers</h2>
          <p className="text-lg mb-4">
            <span className="font-bold">No Warranties:</span> HTMLWebGames.Shop
            is provided "as is" without any representations or warranties,
            express or implied. We make no representations or warranties in
            relation to this website or the information and materials provided
            on this website.
          </p>
          <p className="text-lg mb-4">
            <span className="font-bold">Limitations of Liability:</span> In no
            event shall HTMLWebGames.Shop, nor any of its officers, directors,
            and employees, be liable to you for anything arising out of or in
            any way connected with your use of this website, whether such
            liability is under contract, tort, or otherwise. HTMLWebGames.Shop,
            including its officers, directors, and employees, shall not be
            liable for any indirect, consequential, or special liability arising
            out of or in any way related to your use of this website.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            7. Indemnification
          </h2>
          <p className="text-lg mb-4">
            You agree to indemnify and hold harmless HTMLWebGames.Shop and its
            affiliates, officers, directors, employees, and agents from and
            against any and all claims, liabilities, damages, losses, and
            expenses (including reasonable attorneys' fees) arising out of or in
            connection with your use of the website or violation of these terms.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">8. Governing Law</h2>
          <p className="text-lg mb-4">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of [Your Country/State]. Any disputes
            arising out of or in connection with these terms shall be subject to
            the exclusive jurisdiction of the courts located in [Your
            Country/State].
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            9. Changes to These Terms and Conditions
          </h2>
          <p className="text-lg mb-4">
            We reserve the right to amend these Terms and Conditions at any
            time. Any changes will be posted on this page with an updated
            revision date. Your continued use of the website following the
            posting of changes constitutes your acceptance of such changes.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            10. Contact Information
          </h2>
          <p className="text-lg mb-4">
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
            <a
              href="mailto:contact@htmlwebgames.shop"
              className="text-blue-600"
            >
              contact@htmlwebgames.shop
            </a>
            .
          </p>

          <p className="text-lg font-bold text-center mt-6">
            Thank you for visiting HTMLWebGames.Shop. We appreciate your
            understanding and cooperation.
          </p>
        </div>
      </div>
    </>
  );
}
