import React, { useState } from "react";
import Loader from "../loader";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <Loader />
  ) : (
    <>
      {/* <h1 className="text-center text-2xl text-gray-500">Privacy Policy</h1> */}
      <div className="p-6 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-6">Privacy Policy</h1>
        <p className="text-lg mb-4">Last Updated: [Date]</p>
        
        <p className="text-lg mb-4">
          At <span className="font-bold">HTMLWebGames.Shop</span>, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website and use our services. By using HTMLWebGames.Shop, you consent to the practices described in this policy.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">1. Information We Collect</h2>
        <p className="text-lg mb-4">
          <span className="font-bold">Personal Information:</span> When you interact with our site, such as creating an account, subscribing to our newsletter, or contacting us, you may provide us with personal information including your name, email address, and other details.
        </p>
        <p className="text-lg mb-4">
          <span className="font-bold">Non-Personal Information:</span> We collect non-personal information automatically through cookies and similar technologies, including your IP address, browser type, device information, and browsing behavior. This helps us understand how our site is used and improve your experience.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">2. How We Use Your Information</h2>
        <p className="text-lg mb-4">
          <span className="font-bold">To Provide and Improve Services:</span> We use your information to operate, maintain, and enhance our website and services, including personalizing your experience and responding to your inquiries.
        </p>
        <p className="text-lg mb-4">
          <span className="font-bold">Communication:</span> If you provide your email address, we may use it to send you updates, newsletters, and promotional materials. You can opt-out of these communications at any time.
        </p>
        <p className="text-lg mb-4">
          <span className="font-bold">Analytics:</span> We use non-personal information to analyze usage patterns and improve our site’s performance.
        </p>
        <p className="text-lg mb-4">
          <span className="font-bold">Customer Support:</span> We use your information to provide assistance and support in response to your inquiries.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">3. Cookies and Tracking Technologies</h2>
        <p className="text-lg mb-4">
          We use cookies and similar tracking technologies to enhance your browsing experience and provide personalized content.
        </p>
        <p className="text-lg mb-4">
          <span className="font-bold">Types of Cookies We Use:</span>
        </p>
        <ul className="list-disc list-inside text-lg mb-4">
          <li><span className="font-bold">Essential Cookies:</span> Necessary for the functioning of our website.</li>
          <li><span className="font-bold">Performance Cookies:</span> Help us understand how visitors use our site and improve its performance.</li>
          <li><span className="font-bold">Functional Cookies:</span> Remember your preferences and settings.</li>
        </ul>
        <p className="text-lg mb-4">
          You can manage cookie preferences through your browser settings. However, disabling cookies may affect your ability to use some features of our website.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">4. Sharing Your Information</h2>
        <p className="text-lg mb-4">
          We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share non-personal information with third-party service providers to help us operate our website and services, provided they agree to keep this information confidential. We may also disclose your information if required by law or to protect our rights.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">5. Data Security</h2>
        <p className="text-lg mb-4">
          We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">6. Third-Party Links</h2>
        <p className="text-lg mb-4">
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to review their privacy policies.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">7. Children’s Privacy</h2>
        <p className="text-lg mb-4">
          HTMLWebGames.Shop is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">8. Changes to This Privacy Policy</h2>
        <p className="text-lg mb-4">
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">9. Contact Us</h2>
        <p className="text-lg mb-4">
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@htmlwebgames.shop" className="text-blue-600">contact@htmlwebgames.shop</a>.
        </p>

        <p className="text-lg font-bold text-center mt-6">Thank you for visiting HTMLWebGames.Shop. We value your privacy and are committed to protecting your information.</p>
      </div>
    </div>
    </>
  );
}
