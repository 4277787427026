import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setLocation } from "../redux/location";

export default function Footer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const ReduxLocation = useSelector((state) => state.location.location);

  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setActiveLink(ReduxLocation);
  }, [ReduxLocation]);

  useEffect(() => {
    dispatch(setLocation(activeLink));
  }, [dispatch, activeLink]);

  return (
    <>
      <footer className="bg-lime-800 rounded-lg shadow m-2 text-white">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-center text-white">
            © {currentYear}
            <Link to="/" className="hover:underline mx-2 text-blue-500">
              Html Web Games Shop
            </Link>
            . All Rights Reserved.
          </span>
          <ul className="flex flex-wrap flex-col sm:flex-row items-center mt-3 text-sm font-medium text-white sm:mt-0">
            <li className="my-2 sm:my-0">
              <Link
                to="/about"
                className={`hover:underline me-4 md:me-6 ${
                  activeLink === "about"
                    ? "font-bold text-lime-300"
                    : "md:text-white"
                }`}
                onClick={() => handleLinkClick("about")}
              >
                About
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link
                to="/privacy-policy"
                className={`hover:underline me-4 md:me-6 ${
                  activeLink === "privacy-policy"
                    ? "font-bold text-lime-300"
                    : "md:text-white"
                }`}
                onClick={() => handleLinkClick("privacy-policy")}
              >
                Privacy Policy
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link
                to="/term-condition"
                className={`hover:underline me-4 md:me-6 ${
                  activeLink === "term-condition"
                    ? "font-bold text-lime-300"
                    : "md:text-white"
                }`}
                onClick={() => handleLinkClick("term-condition")}
              >
                Term Condition
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link
                to="/contact-us"
                className={`hover:underline ${
                  activeLink === "contact-us"
                    ? "font-bold text-lime-300"
                    : "md:text-white"
                }`}
                onClick={() => handleLinkClick("contact-us")}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
