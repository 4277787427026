import React from "react";
import loader from "./assest/images/loader-9184_256.gif";
const Loader = () => {
  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{
        minHeight: "calc(100vh - 300px)",
      }}
    >
      <img src={loader} className="h-32 w-32" alt="Loader" />
    </div>
  );
};

export default Loader;
